import Logo from '../../svg/logo.png'
import './LandingPage.css'
import { Link } from 'react-router-dom'
import { postFilters, getAllProvinces, setProvinceAndCitySelected } from '../../store/slices/index'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { FIGUS_API_URL } from '../../constants'
import { useSelector } from 'react-redux'
import { getCityCoords } from '../../services'

export default function LandingPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [province, setProvince] = useState('')
    const [city, setCity] = useState('')
    const provinces = useSelector(state => state.users.allProvinces)

    useEffect(() => {
        axios.post(`${FIGUS_API_URL}/analytics/visit`);
        getAllProvinces(dispatch);

    }, [])

    function handleSelectCity(city) {
        if (city === '') {
            toast.error('Por favor seleccione una provincia')
        } else {
            const cityCoords = getCityCoords(provinces, city)
            dispatch(setProvinceAndCitySelected({ province, city }))
            dispatch(postFilters(false, city, cityCoords, 100))
            axios.post(`${FIGUS_API_URL}/analytics/visit-city?city=${city}`).then(() => { })
            navigate('/home')

        }

    }
    function handleProvinceCity(e) {
        e.preventDefault()
        setProvince(e.target.value)
    }
    return (
        <>
            <div className='landing-page-background'>
                <Toaster />
                <div className='container-img-landing-page' >
                    <img src={Logo} alt="logo" className='img-logo-landing-page'></img>
                </div>
                <div className='container-text-landing-page'>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <p className='first-title-landing-page'>Mis Figus</p>
                        <p className='title-landing-page'>Conseguí las figuritas</p>
                        <p className='title-landing-page' > más buscadas del país!</p>
                    </div>

                    <div className='container-select-city-landing-page' style={{ marginBottom: "30px" }}>
                        <p style={{ fontSize: "24px", fontWeight: "1000" }}>Selecciona tu provincia</p>
                        <select className='select-landing' type="name" value={province} onChange={(e) => { handleProvinceCity(e) }}>
                            <option value="" disabled>Elija una provincia</option>
                            {provinces?.map((citi, i) => {
                                return (
                                    <option key={i} value={citi._id}>{citi.province} </option>
                                )
                            })}
                        </select>
                    </div>
                    {province.length > 2 ?
                        (
                            <div className='container-select-city-landing-page' style={{ marginBottom: "30px" }}>
                                <p style={{ fontSize: "24px", fontWeight: "1000" }}>Elija una localidad</p>
                                <select className='select-landing' type="name" value={city} onChange={(e) => { handleSelectCity(e.target.value) }}>
                                    <option value="" disabled>Elija una localidad</option>
                                    {provinces.filter(localidad => localidad.province === province)[0]?.cities.map((item, i) => {
                                        return (<option key={i} value={item.city}> {item.city} </option>)

                                    })
                                    }

                                </select>
                            </div>
                        )
                        : (null)
                    }

                    <div className='container-select-city-landing-page' style={{ marginTop: "100px" }}>
                        <a href='https://cafecito.app/misfigus' className="text-of-select-city" rel='noopener' target='_blank'><img src='https://cdn.cafecito.app/imgs/buttons/button_5.png' alt='Invitame un café en cafecito.app' /></a>
                        <Link to="/HowTo" style={{ textDecoration: "underline", color: "black" }}><p className="text-of-select-city" style={{ fontSize: "14px" }}>Como funciona?</p></Link>
                        <a href="https://instagram.com/aguzbruno"  ><p style={{ textDecoration: "underline", color: "black", fontSize: "14px" }} className="text-of-select-city">Queres agregar una localidad? Contactanos!</p></a>
                    </div>

                </div>
            </div>
        </>
    )
}