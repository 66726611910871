import { useSelector } from 'react-redux';
import 'leaflet/dist/leaflet.css';
import { useEffect } from 'react';
// import {useMap} from 'react-leaflet'


export default function MapFunctions({}) {
    // const map = useMap()
    const globalMap = useSelector((state) => state.users.globalMap);
    const selectedCityCoords = useSelector(
        (state) => state.users.selectedCityCoords
    );
    const {selected: select} = useSelector((state) => state.users);
    useEffect(() => {
        if (!Array.isArray(select)) {
            const coords = [Number(select.latitude), Number(select.longitude)]
            globalMap.target.setView(coords);
            setTimeout(function () {
                globalMap.target.flyTo(coords);
            }, 1);
            setTimeout(function () {
                globalMap.target.setView(coords);
            }, 1);
            
        }
    }, [select]);
    useEffect(() => {
        if (Object.keys(selectedCityCoords).length > 0) {
            globalMap.target.setView([
                Number(selectedCityCoords.lat),
                Number(selectedCityCoords.lng),
            ], 13)
            globalMap.target.closePopup()
        }
    }, [selectedCityCoords]);

    return (
        <>
            <div></div>
        </>
    );
}