export default function StoreIssue({store}){
    return(
        <div className="container-store-issue">
            <div className="container-place-map" style={{position:"initial",transform:"translate(0,0)",boxShadow:"none"}}>

            
                <div className="img-place-map" >
                    <img src={store.image_url} alt="imagen de store"></img>
                </div>
                <div class="info-place-map">
                
                <p>Nombre: {store.name}</p>
                <p>Provincia: {store.province}</p>
                <p>Ciudad: {store.city}</p>
                <p>Estado: {store.available_stock ? ('Hay stock'):('No hay stock')}</p>
                <p style={{wordBreak:"break-all",padding:"15px",fontSize:"12px"}}>{store.store_id}</p>
                </div>
            </div>
            <div className="last-user-store-modified">
                <h5>Ultimo usuario en actualizar</h5>
                <p>{store.last_user.name} {store.last_user.last_name}</p>
                <p>{store.last_user.mail}</p>
                <p>{store.last_user.verified}</p>
            
            
            
            </div>
            <button>Borrar store</button>
        </div>
    )
}