import React from 'react';
import { AppBar, Toolbar,useMediaQuery } from '@material-ui/core';
import useStyles from './styles.js';
import './Header.css'
import {Link} from 'react-router-dom'
import { useSelector } from 'react-redux';
import Logo from '../../svg/logo.png'


const Header = () => {
  const isDesktop = useMediaQuery('(min-width:600px)');
  const user = useSelector(state=>state.users.user)
  const classes = useStyles();
  
  return (<>
    <AppBar position="static">
      <Toolbar className={classes.toolbar} style={{backgroundColor:"#8E1F30"}}>
      <Link to='/home' style={{textDecoration:"none",color:"white",fontSize:"22px", fontFamily:"DM Sans",display:'flex',alignItems:'center'}} >
        <img src={Logo} alt="logo" style={{width:"30px",paddingTop:"4px",paddingBottom:"4px"}}></img>
          <p style={{width:"70px",margin:"0px",marginLeft:"5px"}}>Mis Figus</p>
        </Link>
      
      {isDesktop ?(<>
        {/* DESKTOP */}
        <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
          {user?.login?.user?.name ?(<>
          {/* Si hay usuario logeado */}
              <Link to='/album' style={{textDecoration:"none",color:"white",fontSize:"18px",marginRight:"2rem", fontFamily:"DM Sans"}}><button className='button-album-header'>Completá tu album!</button></Link>
              <Link to='/createStore' style={{textDecoration:"none",color:"white",fontSize:"18px",marginRight:"2rem", fontFamily:"DM Sans"}}><p>+ Registra un negocio!</p></Link>
              <p style={{marginRight:"1rem",fontSize:"18px", fontFamily:"DM Sans"}}>Bienvenid@ {user?.login?.user?.name}!</p>
              <Link to='/profile' style={{textDecoration:"none"}} className='profile-picture-header'><p>{user?.login?.user?.name[0].toUpperCase()}</p><p> {user?.login?.user?.last_name[0].toUpperCase()}</p></Link>
          
          </>):(<>
          {/* Si no hay usuario logeado */}
          <Link to ="/create" style={{textDecoration:"none",color:"white",fontSize:"18px",marginRight:"2rem", fontFamily:"DM Sans"}}>
                    Creá tu cuenta
          </Link>
          <Link to ="/login" style={{textDecoration:"none",color:"white",fontSize:"18px",marginRight:"2rem", fontFamily:"DM Sans"}}>
                    Ingresá
                </Link>
          </>)}
        </div>

        </>):(<>

        {/* MOBILE */}
        {user?.login?.user?.name ?(<>
        {/* Si hay usuario logeado */}
        <Link to='/album' style={{textDecoration:"none",color:"white",fontSize:"18px",marginRight:"0px", fontFamily:"DM Sans"}}><button className='button-album-header'>Completá tu album!</button></Link>
                    <Link to='/profile' style={{textDecoration:"none",color:"white"}} ><p style={{width:"40px",margin:"0px",marginLeft:"35px",fontFamily:'DM Sans'}}>Menu</p></Link>
                    <Link to='/profile' style={{textDecoration:"none"}} className='profile-picture-header'><p>{user?.login?.user?.name[0].toUpperCase()}</p><p> {user?.login?.user?.last_name[0].toUpperCase()}</p></Link>
        </>):(<>
        {/* Si no hay usuario logeado */}
        <Link to ="/login" style={{textDecoration:"none",color:"white",fontSize:"18px",marginRight:"2rem", fontFamily:"DM Sans"}}>
                    Ingresá
                </Link>
        </>)}

        </>)}
      </Toolbar>
    </AppBar>
    </>
  );
};

export default Header;
