import React from 'react';
import './PlaceDetails.css';
import Circle from '../Circle/Circle';
import { useSelector, useDispatch } from 'react-redux';
import { getSelected, getComment } from '../../store/slices/index';
import Verified from '../../svg/verification.svg';
import { AppBar, Toolbar,useMediaQuery } from '@material-ui/core';



const PlaceDetails = ({ place }) => {
    const isDesktop = useMediaQuery('(min-width:600px)');
    const { selected: select } = useSelector((state) => state.users);
    const dispatch = useDispatch();
    const globalMap = useSelector(
        (state) => state.users.globalMap
    );
    const globalMarkers = useSelector(
        (state) => state.users.globalMarkers
    );

    function handleClick() {
        dispatch(getSelected(place));
        const store_id = place.store_id;
        dispatch((dispatch) => {
            getComment(store_id, dispatch);
        });
        if(!isDesktop){
        window.scroll({
            top: 2000,
            behavior: 'smooth',
        });}
        globalMap.target.closePopup();
        globalMarkers[place.store_id].openPopup();
        //aca habia unos timeouts


    }

    return (
        <div className={place?.store_id === select?.store_id ? 'box-container-selected' : 'box-container'} onClick={() => { handleClick(); }}>
            <div className='container-circle'>
                <Circle color={place.available_stock ? "green" : "red"} storeId={""}></Circle>
            </div>
            <div style={{display:"flex",flexDirection:"row",gap:'10px'}}>
                <div className='container-info'>
                    <p className='title' style={place?.name?.length > 15 ? {fontSize:"15px"}:{fontSize:'17px'}}>{place.name}</p>
                    {place?.verified ? (<p className='sub-title' style={{fontWeight:'1000'}}>Precio sobre: ${place?.price}</p>) : (null)}
                    <p className='sub-title'>{place.address}</p>
                </div>
                {place?.verified ? (<img src={Verified} style={{ width: "22px" }} alt="verified" />) : (null)}
            </div>
        </div>
    );




};

export default PlaceDetails;
