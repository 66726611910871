import './Figurita.css';
import { useDispatch, useSelector } from 'react-redux';
import { putFigurita, setGetAlbum } from '../../store/slices/index';

const getContainerClass = (qty) => {
    if (qty === 0) return 'container-figurita';
    if (qty === 1) return 'container-figurita-active';
    if (qty > 1) return 'container-figurita-repeated';
};

export default function Figurita({ figu_id, qty, team_id, increaseMode }) {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.users.user);
    const album = useSelector((state) => state.users.user_album);

    function handleAddFigurita() {
        let albumAux = JSON.parse(JSON.stringify(album));
        const index_team_id = albumAux.findIndex((e) => e.team_id === team_id);
        const index_figu = albumAux[index_team_id].figus.findIndex(
            (e) => e.figu_id === figu_id
        );

        if (increaseMode) {
            if (qty >= 20) return;
            albumAux[index_team_id].figus[index_figu].qty = qty + 1;
        } else {
            if (qty <= 0) return;
            albumAux[index_team_id].figus[index_figu].qty = qty - 1;
        }
        dispatch(setGetAlbum(albumAux));
        putFigurita(
            user?.login?.session_id,
            user?.login?.user?.user_id,
            albumAux
        );
    }

    return (
        <div style={{ position: 'relative' }}>
            <div onClick={handleAddFigurita} className={getContainerClass(qty)}>
                {figu_id}
            </div>
            {qty > 0 && (
                <div className="figus-counter">
                    <label
                        style={{
                            color: 'black',
                            fontFamily: 'DM Sans',
                            fontSize: '9px',
                            fontWeight: 'bold',
                        }}
                    >
                        {qty}
                    </label>
                </div>
            )}
        </div>
    );
}