import Header from '../Header/Header'
import './CreateUser.css'
import { useState,useEffect } from 'react'
import {Link,useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {createUser} from '../../store/slices/index'
import toast, { Toaster } from 'react-hot-toast';
import {postLogin,verifyTokenCreateUser,getAllProvinces} from '../../store/slices/index'

export function CreateUser(){
    const [user,setUser] = useState({name:'',last_name:'',mail:'',password:'',city:''})
    const [secondPart,setSecondPart] = useState(false)
    const [passwordRepeat,setPasswordRepeat] = useState('')
    const [validateName,setValidateName] = useState(true)
    const [validateLastName,setValidateLastName] = useState(true)
    const [recoveryScreen,setRecoveryScreen] = useState(false)
    const [tokenChange,setTokenChange] = useState('')
    const userCreated = useSelector(state=>state.users.user)
    const [validateCreate,setValidateCreate] = useState(0)
    const userCreate = useSelector(state=>state.users.createUser)
    const dispatch = useDispatch()
    const navigate=useNavigate()
    const [province, setProvince] = useState('')
    const [city, setCity] = useState('')
    const provinces = useSelector(state=>state.users.allProvinces)
    const tokenCreateUser = useSelector(state=>state.users.tokenCreateUser)
    
    useEffect(() => {
        getAllProvinces(dispatch);
      }, [])
    useEffect(()=>{
        if(validateCreate>0){
          setTimeout(()=>{
            if (userCreate?.msg === 'Created successfully'){ 
                const login = {mail:user.mail,password:user.password}
              toast.success("Se ha creado el usuario correctamente") ;
              setRecoveryScreen(true)   
              dispatch(postLogin(login))
           
            }else {
                toast.error("Ha ocurrido un error al crear el usuario") ;
                
            }
          });
        }
      },[userCreate])
      useEffect(()=>{
        
      })

      useEffect(()=>{
        if(validateCreate>0){
            if(tokenCreateUser?.validation){
                toast.success('Su e-mail ha sido verificado correctamente')
                setTimeout(()=>{
                navigate('/home')      
                },2000)
            }else{
                toast.error('El token ingresado no es valido , intente nuevamente')
            }
    }
    },[tokenCreateUser])

   
   

    function handleName(e){
        e.preventDefault()
        const createUserAux = {name:e.target.value,last_name:user.last_name,mail:user.mail,password:user.password,city:user.city}
        setUser(createUserAux)
        setValidateName(true)
    }
    function handleLastName(e){
        e.preventDefault()
        const createUserAux = {name:user.name,last_name:e.target.value,mail:user.mail,password:user.password,city:user.city}
        setUser(createUserAux)
        setValidateLastName(true)
    }
    function handleEmail(e){
        e.preventDefault()
        const createUserAux = {name:user.name,last_name:user.last_name,mail:e.target.value,password:user.password,city:user.city}
        setUser(createUserAux)
    }
    function handlePassword(e){
        e.preventDefault()
        const createUserAux = {name:user.name,last_name:user.last_name,mail:user.mail,password:e.target.value,city:user.city}
        setUser(createUserAux)
    }
    function handlePasswordRepeat(e){
        e.preventDefault()
        setPasswordRepeat(e.target.value)
    }
    function handleSecondCreate(e){
        e.preventDefault()
        
        if(user.name.length>2 && user.last_name.length>2 && user.city.length>2){
            setSecondPart(true)
        } else{toast.error('Por favor rellene los campos correctamente')
        }
        
    }
    function handleFinish(e){
        e.preventDefault()
        if( user.mail.length>6 ){
            if(user.password.length>4){
                if(passwordRepeat === user.password && user.name && user.last_name && user.mail && user.password && user.city){
                    
                    dispatch(createUser(user))
                       setValidateCreate(validateCreate+1)
                    } else{
                    toast.error("Se ha generado un error al crear la cuenta") ;
                    setTimeout(()=>{
                        navigate('/')
                    },1000)
                }           
            } else { toast.error('Por favor rellene los campos correctamente')}
        } else { 
            toast.error('Por favor rellene los campos correctamente')}
        
    }
    function handleTokenSuccess(e){
        e.preventDefault()
        verifyTokenCreateUser(userCreated?.login?.user?.mail,dispatch,tokenChange,userCreated?.login?.session_id,userCreated?.login?.expiry_at)
       
    }
    function handleToken(e){
        e.preventDefault()
        setTokenChange(e.target.value)
        
       
    }
    function handleProvinceCity(e){
        e.preventDefault()
        setProvince(e.target.value)
    }
    function handleCity(e){
        e.preventDefault()
        setCity(e.target.value)
        const createUserAux = {name:user.name,last_name:user.last_name,mail:user.mail,password:user.password,city:e.target.value}
        setUser(createUserAux)
    }

    return(<>
    <Header/>
    <Toaster/>
    <div className='login-container-group'>
            
            {!secondPart ? (
                <form onSubmit={(e)=>{handleSecondCreate(e)}}>
                <div className="login-container">
                    <h4>1/2</h4>
                    <p style={{margin:'0px',padding:"15px",fontWeight:"1000",fontSize:"14px"}}>Al crearte una cuenta y validarla, podrás agregar negocios, cambiar su estado y realizar comentarios</p>
                    <p className='credentials-login'>Completá con tus datos</p>
                    <p className='credentials-login'>Nombre</p>
                    <input type="name" value={user.name} onChange={(e)=>{handleName(e)}} className='input-login'></input>
                    {!validateName ? <p className='text-login-wrong'>Por favor ingrese un nombre valido</p>:null}
                    <p className='credentials-login'>Apellido</p>
                    <input type="name" value={user.last_name} onChange={(e)=>{handleLastName(e)}} className='input-login' ></input>
                    {!validateLastName ? <p className='text-login-wrong'>Por favor ingrese un apellido valido</p>:null}
                    <p className='credentials-login'>Selecciona tu provincia</p>
                        <select className='input-login' type="name" value={province} onChange={(e) => { handleProvinceCity(e) }}>
                            <option value="" disabled>Elige tu provincia</option>
                            {provinces?.map((citi, i) => {
                                return (
                                    <option key={i} value={citi._id}>{citi.province} </option>
                                )
                            })}
                        </select>


                        <p className='credentials-login'>Selecciona tu ciudad</p>
                        <select className='input-login' type="name" value={city} onChange={(e)=>{handleCity(e)}}>
                        <option value="" disabled>Elige una opción</option>
                        {provinces.filter(localidad => localidad.province === province)[0]?.cities.map((item,i)=>{  
                                        return(<option key={i} value={item.city}> {item.city} </option>)

                                })
                            }
                        </select>

                    <input value='Continuar' type="submit" className='login-button-to-password' />
                    <Link to='/login' style={{textDecoration:"none"}} className='crear-cuenta'>Ya tengo una cuenta</Link>
                </div>
                </form>
                
                
                ):( !recoveryScreen ?(
                <form  onSubmit={(e)=>{handleFinish(e)}}>
                    <div className="login-container">
                        <h4>2/2</h4>
                    <p className='credentials-login'>Ahora ingresá tu e‑mail</p>
                    <input autofocus type="email" value={user.mail} onChange={(e)=>{handleEmail(e)}} className='input-login'></input>
                    
                    <p className='credentials-login'>Ingresa tu contraseña</p>
                    <input type="password" value={user.password} onChange={(e)=>{handlePassword(e)}} className='input-login' ></input>
                    <p className='credentials-login'>Repite la contraseña</p>
                    <input type="password" value={passwordRepeat} onChange={(e)=>{handlePasswordRepeat(e)}} className='input-login' ></input>
                    { passwordRepeat !== user.password ? <p className='text-login-wrong'>Por favor controle que las contraseñas sean iguales</p>:null}
                    
                    <input className='login-button-to-password' type='submit' value='Finalizar'/>
                    </div>
                </form>):(
                
                <form onSubmit={(e)=>{handleTokenSuccess(e)}}>
                    <div className="login-container" style={{padding:"10px"}}>
                    <p  style={{margin:'0px',padding:"15px",fontWeight:"1000",fontSize:"22px"}} className='credentials-login'>Ahora solo resta verificar su e-mail
                    </p>
                    <p style={{margin:'0px',padding:"15px",fontWeight:"1000",fontSize:"16px"}}>El codigo se ha enviado al mail con el que usted se ha registrado
                    </p>
                    <p>
                            Ingrese el código recibido</p>
                    <input type="name" value={tokenChange} onChange={(e)=>{handleToken(e)}} className='input-token-recovery'></input>
                    <input  className='login-button-to-password'  type="submit" value="Confirmar" />
                    </div>
                </form>
                
                )

                
                
                )
                
                
                }
            
        </div>
        </>
    )
}