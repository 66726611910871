import './Circle.css'
import { useSelector } from 'react-redux'

export default function Circle({color,map,storeId}){
    const selected = useSelector(state=>state.users.selected)
    return(
        <div style={ map ? { transform: 'translate(-50%,-50%)' }: ({})} className={color==='green' ? "circle-container-green": color ==='blue' ? 'circle-container-blue': 'circle-container-red'}>
            {selected.store_id === storeId ? ( <div className="circle-white" style={{backgroundColor:"#191D32"}}>
                
            </div>):(<div className="circle-white">
                
                </div>)}

        </div>
    )
}