import { useDispatch, useSelector, Provider } from 'react-redux';
import {
    getSelected,
    getComment,
    setGlobalMarkers,
} from '../../store/slices/index';

import { useMap, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { Icon } from 'leaflet';

import PlaceMapDetails from '../PlaceMapDetails/PlaceMapDetails.jsx';
import 'leaflet/dist/leaflet.css';
import { useRef } from 'react';


export default function CustomMarker({ place, isDesktop }) {
    const markerRef = useRef(null)
    
    const {selected: select} = useSelector((state)=>state.users)
    const dispatch = useDispatch();

    function handleClick(place) {
        dispatch(getSelected(place));
        const store_id = place.store_id;
        dispatch((dispatch) => {
            getComment(store_id, dispatch);
        });
    
    }

    function passReference() {
        const markerStoreRef = {
            store_id: place.store_id,
            marker: markerRef.current
        }
        dispatch(setGlobalMarkers(markerStoreRef))
    }
    const getIcon = (available_stock) => {
        const iconUrl = available_stock
        ? '/circle-green.png'
        : '/circle-red.png';
        const icon = new Icon({
            iconUrl: iconUrl,
            iconRetinaUrl: iconUrl,
            iconSize: available_stock ? [27, 27] : [25, 25],
        });
        return icon;
    };
    return (
        <>
            <Marker
                ref={markerRef}
                position={[Number(place.latitude), Number(place.longitude)]}
                icon={getIcon(place.available_stock)}
                eventHandlers={{
                    click: (e) => {
                        handleClick(place);
                    },
                }}

            >
            <Popup
            closeOnClick={false}
            closeOnEscapeKey={false}
            >
                {select.name && isDesktop ? (
                    <PlaceMapDetails  place={select}></PlaceMapDetails>
                ) : null}
                {select.name && !isDesktop ? (
                    <PlaceMapDetails
                        isMobile={true}
                        place={select}
                    ></PlaceMapDetails>
                ) : null}
            </Popup>
            </Marker>
            {passReference()}
        </>
    );
}
