import Header from '../Header/Header'
import List from '../List/List';
import Map from '../Map/Map';
import { useEffect } from "react";
import { useState } from "react";
import { getAllProvinces } from '../../store/slices/index'
import { useDispatch } from 'react-redux'
import { FIGUS_API_URL } from '../../constants'
import './Home.css'
import { useMediaQuery } from '@material-ui/core';


export default function Home (){
    const isDesktop = useMediaQuery('(min-width:600px)');
    const [coordinates, setCoordinates] = useState({lat:-34.921485335697, lng:-57.954521740507666})
    const [userCoordinates, setUserCoordinates] = useState({})
    const [bounds, setBounds] = useState({})
    
    const dispatch = useDispatch()
    useEffect(() => {
      getAllProvinces(dispatch);
    }, [])
    useEffect(() => {
      setTimeout(()=>{

            navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
               setCoordinates({lat: latitude, lng: longitude})
               setUserCoordinates({lat: latitude, lng: longitude})
          
          })
        },2000)
    }, [])
return(
<>
    
      <Header />
      <div  className="container-all" >
          <div className="container-list">
            <List
            isDesktop={isDesktop}
            />
          </div>
        
          <div className="container-map">
            <Map
              userCoordinates={userCoordinates}
              setCoordinates={setCoordinates}
              setBounds={setBounds}
              isDesktop={isDesktop}
              />
        </div>
      </div>
      </>)
}