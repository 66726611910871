import Header from '../Header/Header';
import './Album.css';
import Figurita from '../Figurita/Figurita';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAlbum,getStats } from '../../store/slices/index';
import { FormGroup, FormControlLabel} from '@material-ui/core';
import { IOSSwitch } from '../IOSSwitch/Switch';
import { Link } from 'react-router-dom';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CircularProgress, {
    CircularProgressProps,
  } from '@mui/material/CircularProgress';
  import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function Album() {
    const user = useSelector((state) => state.users.user);
    const dispatch = useDispatch();
    const user_album = useSelector((state) => state.users.user_album);
    const stats_album = useSelector((state) => state.users.stats_album);
    const [stateAlbum,setStateAlbum] = useState('todas')
    const [increaseMode, setIncreaseMode] = useState(true);
    const [write, setWrite] = useState('Agregar');



    useEffect(() => {
        if (user?.login) {
            getAlbum(user?.login?.session_id, dispatch).then(() => {});
            
        }
    }, [user, dispatch]);
    useEffect(() => {
        if (stateAlbum === 'estadisticas') {
            getStats(user?.login?.session_id, dispatch).then(() => {});
            
        }
    }, [stateAlbum, dispatch]);



    function CircularProgressWithLabel(
        props
      ) {
        return (
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >{`${Math.round(props.value)}%`}</Typography>
            </Box>
          </Box>
        );
      }

    function handleOnChange() {
        setIncreaseMode(!increaseMode);
        if (write === 'Agregar') {
            setWrite('Restar');
        } else {
            setWrite('Agregar');
        }
    }
    function handleRepetidas(album){
        const albumFaltante = []
        for(let i=0;i<album.length;i++){
            const team = []
            for(let j=0;j<album[i].figus.length;j++){
                if(album[i].figus[j].qty>1){
                    team.push(album[i].figus[j])
                }
            }
            albumFaltante.push({'figus':team,'acronym':album[i].acronym,'name':album[i].name})
        }
        return albumFaltante
    }
    function handleFaltante(album){
        const albumFaltante = []
        for(let i=0;i<album.length;i++){
            const team = []
            for(let j=0;j<album[i].figus.length;j++){
                if(album[i].figus[j].qty<1){
                    team.push(album[i].figus[j])
                }
            }
            albumFaltante.push({'figus':team,'acronym':album[i].acronym,'name':album[i].name})
        }
        return albumFaltante
    }

    return (
        <>
            <Header />
            <div className="container-album">
                {user?.login?.user?.name ? 
                (<>
                    <h1 style={{ borderBottom: '1px solid black', width: '140px' }}>
                        Mi album
                    </h1>
                    <h4>
                        Pronto tendrás acceso a la posiblidad de que el sistema encuentre quien necesite una repetida tuya y tenga una que a vos te falte, para intercambiar repetidas con gente de tu misma localidad!
                    </h4>
                    <ToggleButtonGroup
                        color="secondary"
                        value={stateAlbum}
                        exclusive
                        onChange={(e)=>{setStateAlbum(e.target.value)}}
                        aria-label="Platform"
                        >
                        <ToggleButton value="todas" className="toggle-album">Todas</ToggleButton>
                        <ToggleButton value="faltantes" className="toggle-album">Me Faltan</ToggleButton>
                        <ToggleButton value="repetidas" className="toggle-album">Repetidas</ToggleButton>
                        <ToggleButton value="estadisticas" className="toggle-album">Estadisticas</ToggleButton>
                    </ToggleButtonGroup>
                    {stateAlbum === 'todas' ? 
                    (<>
                        <div className='form-add-remove-figuritas'>
                            <FormGroup >
                                <FormControlLabel 
                                    checked={increaseMode}
                                    onChange={handleOnChange}
                                    control={<IOSSwitch />}
                                    label={write}
                                />
                            </FormGroup>
                        <h5 style={{marginTop:"5px",marginBottom:"0px"}}>Para quitar una figurita presione el boton de arriba y estará restando en vez de agregando</h5>
                        </div>

                        <div className="container-titles-album">
                            {user_album?.map((e, i) => (
                                
                                    <div
                                        key={i}
                                        className="container-title-figurita-album"
                                    >
                                        <h4 style={{ borderBottom: '1px solid black' }}>
                                            {e.acronym} - {e.name}
                                        </h4>
                                        <div className="container-figuritas-album">
                                            {e.figus?.map((item, i) => (
                                                    <Figurita
                                                        key={i}
                                                        figu_id={item.figu_id}
                                                        team_id={e.team_id}
                                                        qty={item.qty}
                                                        increaseMode={increaseMode}
                                                    />
                                            ))}
                                        </div>
                                    </div>
                                
                            ))}
                        </div>
                        </>
                    ):(
                        stateAlbum === 'faltantes' ?
                        (<>
                            {
                                <div className="container-titles-album">
                            {handleFaltante(user_album)?.map((e, i) => (
                                
                                    <div
                                        key={i}
                                        className="container-title-figurita-album"
                                    >
                                        <h4 style={{ borderBottom: '1px solid black' }}>
                                            {e.acronym} - {e.name}
                                        </h4>
                                        <div className="container-figuritas-album">
                                            {e.figus?.map((item, i) => (
                                                    <Figurita
                                                        key={i}
                                                        figu_id={item.figu_id}
                                                        team_id={e.team_id}
                                                        qty={item.qty}
                                                        increaseMode={increaseMode}
                                                    />
                                            ))}
                                        </div>
                                    </div>
                                
                            ))}
                                </div>
                            }
                        </>):(
                                stateAlbum === 'repetidas' ?
                            (<>
                                {
                            
                            
                                <div className="container-titles-album">
                                    {handleRepetidas(user_album)?.map((e, i) => (
                                        
                                            <div
                                                key={i}
                                                className="container-title-figurita-album"
                                            >
                                                <h4 style={{ borderBottom: '1px solid black' }}>
                                                    {e.acronym} - {e.name}
                                                </h4>
                                                <div className="container-figuritas-album">
                                                    {e.figus?.map((item, i) => (
                                                            <Figurita
                                                                key={i}
                                                                figu_id={item.figu_id}
                                                                team_id={e.team_id}
                                                                qty={item.qty}
                                                                increaseMode={increaseMode}
                                                            />
                                                    ))}
                                                </div>
                                            </div>
                                        
                                    ))}
                                </div>}
                            </>):(
                                stats_album?.unique_total ? (
                                <>
                                <div className='container-stats-album'>
                                    <div className='box-container-stats-album'>
                                        <h2>670</h2>
                                        <p>Totales</p>
                                    </div>
                                    <div className='box-container-stats-album'>
                                        <h2>{670-Number(stats_album?.unique)}</h2>
                                        <p>Me faltan</p>
                                    </div>
                                </div>
                                <div className='container-stats-album'>
                                    <div className='box-container-stats-album'>
                                        <h2>{stats_album?.repeated}</h2>
                                        <p>Repetidas</p>
                                    </div>
                                    <div className='box-container-stats-album'>
                                        <CircularProgressWithLabel value={stats_album?.completed_percentage?.replace('%', '')} />
                                        <p>Album completado</p>
                                    </div>
                                </div>
                                <div className='container-stats-album'>
                                    <div className='box-container-stats-album'>
                                        <CircularProgressWithLabel value={stats_album?.team_stats[10]?.completed_percentage.replace('%', '')} />
                                        <p>Completado Argentina</p>
                                    </div>
                                    <div className='box-container-stats-album'>
                                        <CircularProgressWithLabel value={stats_album?.best_team?.completed_percentage?.replace('%', '')} />
                                        <p>Mi mayor selección: {stats_album?.best_team.name}</p>
                                    </div>
                                </div>
                                </>):(<h2>Cargando..</h2>)
                            )
                        )
                    )}
                    
                                                    
                </>):(
                    <>
                    
                    <div className="container-titles-album">
                        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                        <p className="text-modal" style={{width:"auto"}}>
                            Para completar el album primero debe iniciar sesión!
                        </p>
                        </div>
                        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                            <Link to="/login">
                                <button
                                    className="button-accept-modal"
                                    style={{ width: '150px' }}
                                >
                                    Iniciar sesion
                                </button>
                            </Link>
                        </div>
                    </div>
                    </>
                )}
            </div>
        </>
    );
}