import { useSelector } from "react-redux"
import Header from "../Header/Header"
import './Admin.css'
import axios from "axios"
import { useState } from "react"
import Issue from "./Issue"
import { useEffect } from "react"
import { FIGUS_API_URL } from '../../constants'

export default function Admin(){
    const user = useSelector(state=>state.users.user)
    const [store,setStore] = useState('')
    const [issues,setIssues] = useState('')
    useEffect(()=>{
        
        axios.get(`${FIGUS_API_URL}/issues?session_id=${user?.login?.session_id}`).then((response)=>{
        
            if(response.data.message === 'Successful Request'){
                const issuesActive = response.data.data.filter(item=>item.active)
                setIssues(issuesActive) //doble data por axios
            }
        })
        
    },[user])
    
    return(
        issues.length > 0 ?(
            <>
            <Header/>
        <div className="container-issues">
            {issues?.map((issue,i)=>{
                return(<>
                    <Issue id={i} issue={issue} user={user} />
                    </>
                )
            })}
        </div>
        </>
        ):(
            null
        )
    )

}