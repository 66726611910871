import { useSelector } from "react-redux"
import Header from "../Header/Header"
import './MisDatos.css'
import { Link } from "react-router-dom"

export default function MisDatos(){
    const user = useSelector(state=>state.users.user)
    return(
        <>
        <Header/>
        <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",fontFamily:"DM Sans"}}>
            <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",fontFamily:"DM Sans"}}>
                <h1>Mis datos</h1>
                {user?.login?.user?.name ? (<>
                <h4>Datos de cuenta</h4>
                <div className="table-data-my-profile">

                
                    <div className="box-data-my-profile">
                        <p className="title-data-my-profile">Nombre y Apellido</p>
                        <p>{user?.login?.user?.name} {user?.login?.user?.last_name}</p>
                    </div>
                    <div className="box-data-my-profile">
                        <p className="title-data-my-profile"> Mail registrado</p>
                        <p>{user?.login?.user?.mail}</p>
                    </div>
                    <div className="box-data-my-profile">
                        <p className="title-data-my-profile">Localidad registrada</p>
                        {
                            user?.login?.user?.city?.length>1 ? (<p>{user?.login?.user?.city}</p>):(<p>
                            Aun no ha registrado una localidad
                            </p>)
                        }
                    </div>
                    <div className="box-data-my-profile">
                        <p className="title-data-my-profile">E-mail verificado</p>
                        {
                            user?.login?.user?.verified ? (<p>Su e-mail está verificado</p>):(
                            <>
                            <p>
                            Aun no ha verificado su email
                            </p>
                            <Link to='/verify/email' ><button className="button-album-header" style={{marginTop:"15px",marginBottom:"15px"}}>Verificar ahora</button></Link>
                            </>)
                        }
                    </div>

                </div></>):(<div>
                    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                            <Link to="/login">
                                <button
                                    className="button-accept-modal"
                                    style={{ width: '250px' }}
                                >
                                    Por favor Inicie sesion
                                </button>
                            </Link>
                        </div>
                    </div>)}
                
                
                
            </div>
        </div>
        </>
    )
}