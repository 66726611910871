import { configureStore } from '@reduxjs/toolkit';
import users from './slices/index';

export default configureStore({
    reducer: {
        users
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});