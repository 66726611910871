import Header from "../Header/Header";
import VerifiedLogo from '../../svg/verification.svg'
import { Link } from "react-router-dom";


export default function Verified(){
    return(<>
        <Header/>
        <div className="container-howto">
        <h1>Verificado <img src={VerifiedLogo} style={{width:"18px"}} alt="verified"/> </h1>
        <p>Para agregar un comercio cualquier usuario (sea dueño o no) puede agregarlo desde: </p>
            <Link to='/createStore' style={{textDecoration:"underline",color:"black",fontSize:"20px"}}>+Registrar un negocio</Link>

        <p> Una vez que este agregado, cualquier usuario de la plataforma lo podrá ver en el mapa y cambiar el estado del stock.</p>
        <p> Por eso para aquellos dueños de negocio, creamos el verificado <img src={VerifiedLogo} style={{width:"18px"}} alt="verified"/></p>
        <h2>Que ventajas trae?</h2>
        <p> Aquellos comercios que tengan el verificado aseguran al usuario la existencia del mismo, aparecen más arriba en el orden, tienen precio del paquete
            y el stock solo podrá ser cambiado por el dueño. Además se verá diferenciado del resto con el simbolo azul a su derecha</p>
            <div className="container-img">
                <img className="img-howto" style={{height:"100px" }} src="https://i.postimg.cc/TYKJh6Zb/diferencia.png" alt="mapa completo"></img>
                
                <img className="img-howto" style={{height:"200px" }} src="https://i.postimg.cc/KYMWTTwn/verification2.png" alt="mapa completo"></img>
            </div>
        

        <h2>Que necesito para tenerlo?</h2>
        <p>Para conseguirlo deberia hablarnos a:</p>
        <a href='https://instagram.com/aguzbruno' style={{textDecoration:"underline",color:"black",fontSize:"20px"}}>Instagram de aguzbruno</a>
        <p>desde el instagram del negocio, diciendo provincia, localidad y nombre del local registrado en MisFigus, además de un mail de usuario para llevar a cabo la administracion.</p>
        <p>En caso de no tener instagram, le vamos a pedir un video mostrando su local en modo selfie.</p>
        </div>
        
        </>
    )
}