import Header from "../Header/Header";
import './VerifyEmail.css'
import { useEffect, useState } from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'
import {postLogin, verifyTokenCreateUser,recoveryPassword} from '../../store/slices/index'
import { useDispatch, useSelector } from 'react-redux'
import {useNavigate} from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import { FIGUS_API_URL } from "../../constants";

export default function VerifyEmail(){
    const navigate = useNavigate()
    const user = useSelector(state=>state.users.user)
    const [token,setToken] = useState('')
    const [codigoEnviado,setCodigoEnviado] = useState(false)
    const tokenVerify = useSelector(state=>state.users.tokenCreateUser)
    const [tokenVerify2,setTokenVerify2] = useState(false)
    const dispatch = useDispatch()
    const [validateCreate,setValidateCreate] = useState(0)
    
   
//    useEffect(()=>{
//         if(validateCreate>0){
            
//             if(tokenVerify?.validation){
                
//                 toast.success('Su e-mail ha sido verificado correctamente')
//                 navigate('/home')
//             }else{
//                 toast.error('El token ingresado no es valido, intente nuevamente')
//             }
//         }
//     },[tokenVerify])
    
    function onSubmitMail(e){
        e.preventDefault()
        if(user?.login?.user?.mail.length>6){
            axios.get(`${FIGUS_API_URL}/users/verification?mail=${user?.login?.user?.mail}`)
            setCodigoEnviado(true)
        }else{
            toast.error('Por favor ingrese el mail correctamente')
        }
    }
    function handleTokenSuccess(e){
        e.preventDefault()
        setValidateCreate(1)
        
        toast.promise(
            verifyTokenCreateUser(user?.login?.user?.mail,dispatch,token,user?.login?.session_id,user?.login?.expiry_at).then(() => {
            
            }),
            {
                loading: 'Verificando...',
                success: <b>Se ha verificado su e-mail correctamente</b>,
                error: <b>Ha ocurrido un error</b>,
            }
        ).then(()=>{
            setTimeout(()=>{
                navigate('/home')
            },1000)
        })
        
        
    }
    function handleToken(e){
        e.preventDefault()
        if(e.target.value.length<7){
            setToken(e.target.value)
        }
    }

    return(<>
        <Header/>
        <Toaster/>
        
        {user?.login?.user?.mail ?(
        codigoEnviado ?(<div className='login-container-group'>
            {tokenVerify2 ?(
                        <div className="login-container" style={{padding:"10px"}}>
                        <p className='credentials-login'>Su e-mail ha sido verificado correctamente!</p>
                        </div>
            ):(<>
                <form onSubmit={(e)=>{handleTokenSuccess(e)}}>
            <div className="login-container" style={{padding:"10px"}}>
                <p className='credentials-login'>El codigo se ha enviado al mail con el que se registró
                </p>
                <p>
                        Ingrese el código recibido</p>
                <input type="name" value={token} onChange={(e)=>{handleToken(e)}} className='input-token-recovery'></input>
                <input  className='login-button-to-password'  type="submit" value="Confirmar" />
            </div>
                </form>
                </>
                )}
            </div>
        
            ):(<>
        <div className='login-container-group' >
                <form onSubmit={(e)=>{onSubmitMail(e)}}>
            <div className="login-container" style={{padding:"10px"}}>
                <p className='credentials-login'>Verificá que este e-mail te pertenece
                </p>
                <p>
                        Te enviaremos un codigo de verificación al mail con el que te registraste</p>
                <input  className='login-button-to-password'  type="submit" value="Enviar codigo" />
            </div>
                </form>
                
        </div>
        </>)):(
            <Link to="/login">Primero inicie sesión</Link>
        )}
        </>
    )
}