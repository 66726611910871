export function getCityCoords(provinces,city){
    let mapCityCoordsDb={}
    provinces?.forEach(item=>{
    item.cities.forEach(otroNombre=>{
        mapCityCoordsDb[otroNombre.city]={
                lat:Number(otroNombre.default_location.latitude),
                lng:Number(otroNombre.default_location.longitude)
            }    
    })
    
})
return mapCityCoordsDb[city]
}