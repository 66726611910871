import './Chat.css'

export default function CommentChat({comment}){
    
    function subtractHours(numOfHours, date = new Date()) {
        date.setHours(date.getHours() - numOfHours);

        return date;
    }
    function handleDate(originalDate) {
        const date = new Date(originalDate)
        const fecha = subtractHours(3, date)
        const fechaColoquial = new Intl.DateTimeFormat('es-AR', { dateStyle: 'full', timeStyle: 'short' }).format(fecha)
        return fechaColoquial
    }
    
    return(
        <div className="container-comment-chat">
            <p className='name-comment-chat'>{comment.user.name} {comment.user.last_name}</p>    
            <p className='comment-chat'>{comment.message}</p>    
            <p className='date-comment-chat' style={{textAlign:'right'}}>{handleDate(comment.updated_at)}</p>    
        </div>
    )
}