import { useEffect, useState } from "react"
import axios from "axios"
import StoreIssue from "./StoreIssue"
import { FIGUS_API_URL } from '../../constants'


export default function Issue({id,issue,user}){
    const [store,setStore] = useState('')
    const [verStore,setVerStore] = useState(false)

    useEffect(()=>{
        axios.get(`${FIGUS_API_URL}/stores/${issue.store_id}`).then((response)=>{
            setStore(response.data.data) //doble data por axios
        })
        
    },[])
    function handleClickeado(e){
        e.preventDefault();
       
        axios.put(`${FIGUS_API_URL}/issues/${issue.issue_id}/toggle?session_id=${user.login.session_id}&active=false`).then((response)=>{
            setStore(response.data.data) //doble data por axios
        })
    }
    
    return(
     
        <div key={issue?.store_id} className="box-container-issue" >
            <button onClick={(e)=>{handleClickeado(e)}}>Pasar a revisado</button>
            <h5>{issue?.active ? ('A Revisar'):('Revisado')}</h5>
            <h2>{issue?.subject}</h2>
            {issue?.message?.length ? (<h4>Descripcion: {issue?.message}</h4>):(<h4> Descripcion: No hay descripcion</h4>)}
            <p> Creado por: {issue?.mail}</p>
            <button onClick={()=>{setVerStore(!verStore)}}>Ver negocio</button>
            {store && verStore ? (
            <div className="container-store-issue">
                <StoreIssue store={store} />
            </div>
            ):(null)
            }
            
        </div>
        )

}

