import Header from '../Header/Header'
import './Login.css'
import { useEffect, useState } from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'
import {postLogin} from '../../store/slices/index'
import { useDispatch, useSelector } from 'react-redux'
import {useNavigate} from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';

export function Login(){
    const navigate = useNavigate()
    const [login,setLogin] = useState({mail:"",password:""})
    const [validateEmail,setValidateEmail] = useState(true)
    const [validatePass,setValidatePass] = useState(true)
    const [validateLogin,setValidateLogin] = useState(0)
    const user = useSelector(state=>state.users.user)
    const dispatch = useDispatch()

    useEffect(()=>{
        if(validateLogin>0){
          setTimeout(()=>{
            if (user?.login?.user?.name){
              localStorage.setItem('userData', JSON.stringify(user)); 
              toast.success("Ha iniciado sesion correctamente") ;
              setTimeout(()=>{
                  navigate('/home')      
              },1000)
            }else {
                toast.error("Ingrese las credenciales correctamente") ;
            }
          });
        }
      },[user])

    function handleEmail(e){
        e.preventDefault()
        const loginAux = {mail:e.target.value,password:login.password}
        setLogin(loginAux)
        setValidateEmail(true)
        
      
        
    }
    function handlePassword(e){
        e.preventDefault()
        const loginAux = {mail:login.mail,password:e.target.value}
        setLogin(loginAux)
        setValidatePass(true)
       
    }
    function handleLogin(e){
        e.preventDefault()
        if(login.mail.length<1){
            setValidateEmail(false)
        }else{
            setValidateEmail(true)
        } 
        if(login.password<1){
            setValidatePass(false)
        } else{
            setValidatePass(true)
        }
        

        const fetchData = async () => {
            await   dispatch(postLogin(login))
            await setValidateLogin(validateLogin+1)
          }
        fetchData()
        

    }

    return(<>
    <Header/>
    <Toaster/>
    {user?.login?.user?.name ? (<h1>Bienvenido {user?.login?.user?.name}</h1>):(
        <div className='login-container-group'>
                <form onSubmit={(e)=>{handleLogin(e)}}>
            <div className="login-container">
                <p className='credentials-login'>¡Hola! Ingresá tu e‑mail</p>
                <input type="email" value={login.mail} onChange={(e)=>{handleEmail(e)}} className='input-login'></input>
                {!validateEmail ? <p className='text-login-wrong'>Por favor ingrese un email valido</p>:null}
                <p className='credentials-login'>Ingresa tu contraseña</p>
                <input type="password" value={login.password} onChange={(e)=>{handlePassword(e)}} className='input-login' ></input>
                <Link to ='/login/recovery' style={{textDecoration:"none",fontSize:"12px"}} className='crear-cuenta'> Olvidaste tu contraseña?</Link>
                {!validatePass ? <p className='text-login-wrong'>Por favor ingrese una contraseña valida</p>:null}
                <input  className='login-button-to-password'  type="submit" value="Iniciar sesion" />
                <Link to='/create' style={{textDecoration:"none"}} className='crear-cuenta'>Crear cuenta</Link>
            </div>
                </form>
                
        </div>
    )
    }
        </>
    )
}